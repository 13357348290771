import { combineReducers } from 'redux';
import posts from './posts/reducer';
import post from './post/reducer';
import authentication from './authentication/reducer';
import createPost from './createpost/reducer';

const App = combineReducers({
  posts,
  post,
  createPost,
  authentication
});

export default App;
