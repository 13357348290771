export const POSTS_FETCHING = 'POSTS_FETCHING';
export const POSTS_FETCHED = 'POSTS_FETCHED';
export const POSTS_FETCHED_FAILED = 'POSTS_FETCHED_FAILED';

export const fetchPostsBegin = () => ({
  type: POSTS_FETCHING
});

export const fetchPostsSuccess = posts => ({
  type: POSTS_FETCHED,
  payload: { posts: posts }
});

export const fetchPostsFailure = error => ({
  type: POSTS_FETCHED_FAILED,
  payload: { error }
});

export const fetchPosts = () => {
  return async dispatch => {
    dispatch(fetchPostsBegin());
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/posts/get`);
      const res = handleErrors(response);
      const json = await res.json();
      dispatch(fetchPostsSuccess(json));
      return json;
    } catch (error) {
      return dispatch(fetchPostsFailure(error));
    }
  };
};

const handleErrors = response => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};
