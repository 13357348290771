import React from 'react';
import ReactDOM from 'react-dom';
import App from './store/index';
import Root from './Root';
import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux';
import thunker from 'redux-thunk';

const composeEnhancers =
  process.env.NODE_ENV !== `production`
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const middlewares = [thunker];

const store = createStore(
  App,
  composeEnhancers(applyMiddleware(...middlewares))
);

ReactDOM.render(<Root store={store} />, document.getElementById('root'));
registerServiceWorker();
