import * as api from '../api';

export const IS_AUTHENTICATING = 'IS_AUTHENTICATING';
export const AUTH_PASSED = 'AUTH_PASSED';
export const AUTH_FAILED = 'AUTH_FAILED';

export const TOKEN_VERIFYING = 'TOKEN_VERIFYING';
export const TOKEN_VERIFIED = 'TOKEN_VERIFIED';
export const TOKEN_VERIFIED_FAILED = 'TOKEN_VERIFIED_FAILED';

export const isAuthenticating = () => ({
  type: IS_AUTHENTICATING
});

export const authPassed = json => ({
  type: AUTH_PASSED,
  payload: json
});

export const authFailed = error => ({
  type: AUTH_FAILED,
  payload: error
});

export const isVerifying = () => ({
  type: TOKEN_VERIFYING
});

export const tokenVerified = json => ({
  type: TOKEN_VERIFIED,
  payload: json
});

export const tokenVerificationFailed = error => ({
  type: TOKEN_VERIFIED_FAILED,
  payload: error
});

export const getAuthentication = (username, password) => {
  return async dispatch => {
    dispatch(isAuthenticating());
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_API_URL}/auth/signin`, {
        method: 'POST',
        body: JSON.stringify({ username: username, password: password })
      });
      api.handleStatus(res);
      const json = await res.json();
      sessionStorage.setItem('token', json.accessToken);
      dispatch(authPassed(json));
    } catch (e) {
      dispatch(authFailed(e));
    }
  };
};

export const userIsAuthed = token => {
  return async dispatch => {
    dispatch(isVerifying());
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_API_URL}/verify/userIsAuthed`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Access-Token': token
        })
      });
      const json = await res.json();
      return dispatch(tokenVerified(json.user.roles[0].name));
    } catch (error) {
      console.error('Error', error);
      return dispatch(tokenVerificationFailed(error));
    }
  };
};
