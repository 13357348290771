export const POST_FETCHING = 'POST_FETCHING';
export const POST_FETCHED = 'POST_FETCHED';
export const POST_FETCHED_FAILED = 'POST_FETCHED_FAILED';

export const fetchPostBegin = () => ({
  type: POST_FETCHING
});

export const fetchPostSuccess = post => ({
  type: POST_FETCHED,
  payload: { post: post }
});

export const fetchPostFailure = error => ({
  type: POST_FETCHED_FAILED,
  payload: { error }
});

export const fetchPost = id => {
  return async dispatch => {
    dispatch(fetchPostBegin());
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/post/get/${id}`);
      const res = await handleErrors(response);
      const json = await res.json();
      dispatch(fetchPostSuccess(json));
      return json;
    } catch (error) {
      return dispatch(fetchPostFailure(error));
    }
  };
};

const handleErrors = response => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};
