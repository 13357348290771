import React, { Component } from 'react';
import Moment from 'moment';
import styled from 'styled-components';
import * as postsActions from '../store/posts/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';

const override = css`
  display: block;
  text-align: center;
  width: auto;
  margin-top: 250px;
`;

const StyledUL = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledLI = styled('li')`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }
  i {
    grid-column: 1;
  }
  p,
  h3 {
    grid-column: 2;
  }
`;

const StyledLink = styled(Link)`
  color: #ccc;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

class Posts extends Component {
  componentDidMount () {
    this.props.dispatch(postsActions.fetchPosts());
  }

  render () {
    const { posts, loading } = this.props;
    return (
      <StyledUL>
        {!loading ? (
          posts.posts.map(post => (
            <StyledLI key={post.id} className="post">
              <h3>
                <StyledLink to={'/post/' + post.id}>{post.title}</StyledLink>
              </h3>
              <div>{post.content}</div>
              <div>
                <strong>Author:</strong> {post.author}
              </div>
              <div>
                <strong>Date Posted:</strong>
                {Moment(post.dateCreated).format('dddd, MMMM Do YYYY, h:mm a').toString()}
              </div>
            </StyledLI>
          ))
        ) : (
          <BeatLoader css={override} color={'#eee'} sizeUnit={'px'} size={30} />
        )}
      </StyledUL>
    );
  }
}

const mapsStateToProps = state => {
  return {
    posts: state.posts,
    loading: state.posts.loading,
    error: state.posts.error
  };
};

export default connect(mapsStateToProps)(Posts);
