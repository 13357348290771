import { POSTS_FETCHED, POSTS_FETCHING, POSTS_FETCHED_FAILED } from './actions';

const initialState = {
  posts: [],
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POSTS_FETCHING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case POSTS_FETCHED:
      return {
        ...state,
        loading: false,
        posts: action.payload.posts.reverse()
      };
    case POSTS_FETCHED_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        posts: []
      };
    default:
      return state;
  }
};
