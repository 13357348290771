import React, { Component } from 'react';
import { Redirect, Link, BrowserRouter as Router, Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Post from '../src/components/Post';
import Posts from '../src/components/Posts';
import CreatePost from '../src/components/CreatePost';
import Login from '../src/components/Login';
import { fixed } from './style-utils';
import { Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { connect } from 'react-redux';
import * as authActions from './store/authentication/actions';

const date = new Date();

const GlobalStyle = createGlobalStyle`
  body {
		display: grid;
	}
	
	body,
	html {
			height: 100%;
			margin: 0;
			padding: 0;
	}
	
	h3{
    font-size: 39px;
    font-weight: 400;
    margin: 0 0 10px 0;
	}
`;

const StyledContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 150px 1fr 150px;
  grid-row-gap: 30px;
  grid-template-areas: 'header header header header' 'content content content content'
    'footer footer footer footer';
  margin: auto;
  background-color: #fff;
`;

const StyledHeader = styled.header`
  grid-area: header;
  border-bottom: 1px solid #ddd;
  padding: 0 2vw;
  background: #fff;
`;

const StyledContent = styled.div`
  ${fixed('100%', '960px')};
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'main main main aside';
  grid-column-gap: 25px;
  main,
  aside {
    grid-gap: 10%;
  }
  main {
    grid-area: main;
  }
  aside {
    grid-area: aside;
    border-left: 1px solid #fff;
  }
`;

const StyledFooter = styled.footer`
  grid-area: footer;
  border-top: 1px solid #ddd;
  padding: 10px 2vw;
  background: darken(#fff, 10%);
`;

const Inner = styled.div`
  ${fixed('100%', '960px')};
  display: grid;
  grid-template-columns: 75% auto;
`;

const StyledLink = styled(Link)`
  color: #ddd;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

class App extends Component {
  componentDidMount () {
    if (sessionStorage.getItem('token')) {
      this.props.dispatch(authActions.userIsAuthed(sessionStorage.getItem('token')));
    }
  }

  render () {
    const { isAuthed } = this.props;
    const myCustomTheme = deepMerge(grommet, {
      global: {
        input: {
          padding: '6px',
          weight: 100
        },
        button: {
          padding: {
            horizontal: '10px'
          }
        }
      },
      button: {
        padding: {
          horizontal: '8px',
          vertical: '2px'
        }
      }
    });
    return (
      <React.Fragment>
        <Grommet full theme={myCustomTheme}>
          <GlobalStyle />
          <Router>
            <StyledContainer id="AppRender">
              <StyledHeader>
                <Inner>
                  <div>
                    <div>
                      <StyledLink to={{ pathname: '/' }}>Home</StyledLink>
                    </div>
                    {isAuthed ? (
                      <div>
                        <StyledLink to={{ pathname: '/create-post' }}>Create Post</StyledLink>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <Login />
                  </div>
                </Inner>
              </StyledHeader>
              <StyledContent className="content">
                <main>
                  <div>
                    <Route exact path="/" component={Posts} />
                    <Route
                      exact
                      path="/create-post"
                      render={props =>
                        isAuthed === true ? <CreatePost {...props} /> : <Redirect to="/" />}
                    />
                    <Route exact path="/post/:id" component={Post} />
                  </div>
                </main>
                <aside>
                  <div>this is a sidebar</div>
                </aside>
              </StyledContent>
              <StyledFooter>
                <Inner>
                  <div>&copy; {date.getFullYear()}: Kelley Perry</div>
                </Inner>
              </StyledFooter>
            </StyledContainer>
          </Router>
        </Grommet>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { isAuthed } = state.authentication;
  return {
    isAuthed
  };
};

export default connect(mapStateToProps)(App);
