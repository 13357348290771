import React, { Component } from 'react';
import { TextInput, TextArea, Button } from 'grommet';
import * as createPostActions from '../store/createpost/actions';
import { connect } from 'react-redux';

class CreatePost extends Component {
  constructor (props) {
    super(props);
    this.state = {
      author: '',
      title: '',
      content: '',
      date_created: 'now()'
    };
  }

  handleChange = event => {
    const state = this.state;
    state[event.target.name] = event.target.value;
    this.setState(state);
  };

  handleSubmit = async event => {
    event.preventDefault();
    const data = this.state;
    await this.props.dispatch(createPostActions.createPost(data));
    if (this.props.isCreated) {
      this.props.history.push('/');
    }
  };

  render () {
    return (
      <div className="create-post container">
        <form onSubmit={this.handleSubmit}>
          <TextInput
            type="author"
            placeholder="Author..."
            id="author"
            name="author"
            onChange={this.handleChange}
          />
          <br />
          <TextInput
            type="title"
            placeholder="Title..."
            id="title"
            name="title"
            onChange={this.handleChange}
          />
          <br />
          <TextArea
            value={this.state.content}
            rows="5"
            name="content"
            id="content"
            onChange={this.handleChange}
          />
          <input type="hidden" name="date_created" id="dated_created" />
          <br />
          <br />
          <Button type="submit" plain={false} fill={false}>
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { isCreating, isCreated } = state.createPost;
  return {
    isCreating,
    isCreated
  };
};

export default connect(mapStateToProps)(CreatePost);
