export const FAILED_TO_FIND_URL = 'Failed to find URL: ';

export const handleStatus = res => {
  res = handleUnAuth(res);
  if (res.status === 200) {
    return res;
  }
  else if (res.status === 404) {
    throw new Error(FAILED_TO_FIND_URL + res.url);
  }
  else if (res.status === 401) {
    throw new UnauthedError(res);
  }
  else if (!res.ok) {
    return handleResponse(res).then(json => {
      if (json && json.error) {
        throw new Error(json.error);
      }
      else {
        throw new Error('Failed: ' + res.statusText);
      }
    });
  }
};

export const handleUnAuth = res => {
  if (res.status === 401 || res.status === 403) {
    debounce(e => {
      window.sessionStorage && window.sessionStorage.clear();
      window.location = '/';
    }, 100)();
  }
  return res;
};

function UnauthedError (message) {
  this.message = message;
  this.name = 'Unauthorized';
}

function debounce (func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export const handleResponse = res => {
  if (res) {
    return res.json();
  }
  else {
    return {};
  }
};
