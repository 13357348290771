import React, { Component } from 'react';
import { TextInput, Button } from 'grommet';
import { Box } from 'grommet';
import styled from 'styled-components';
import * as actions from '../store/authentication/actions';
import { connect } from 'react-redux';

const StyledInput = styled(TextInput)`
  margin-bottom: 10px;
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      username: '',
      password: ''
    };
  }

  handleChange = event => {
    const state = this.state;
    state[event.target.name] = event.target.value;
    this.setState(state);
  };

  handleSubmit = event => {
    const { username, password } = this.state;
    event.preventDefault();
    this.props.dispatch(actions.getAuthentication(username, password));
  };

  render() {
    return (
      <Box pad='small'>
        <form onSubmit={this.handleSubmit}>
          <StyledInput
            type='username'
            placeholder='User Name...'
            id='username'
            name='username'
            size='small'
            onChange={this.handleChange}
          />
          <StyledInput
            type='password'
            placeholder='Password...'
            id='password'
            name='password'
            size='small'
            onChange={this.handleChange}
          />
          <Button type='submit' plain={false} fill={false}>
            Submit
          </Button>
        </form>
      </Box>
    );
  }
}

export default connect()(Login);
