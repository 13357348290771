import {
  AUTH_PASSED,
  AUTH_FAILED,
  IS_AUTHENTICATING,
  TOKEN_VERIFYING,
  TOKEN_VERIFIED,
  TOKEN_VERIFIED_FAILED
} from './actions';

const initialState = {
  loading: false,
  isAuthed: false,
  isVerifying: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_AUTHENTICATING:
      return {
        ...state,
        loading: true,
        isAuthed: false
      };
    case AUTH_PASSED:
      return {
        ...state,
        loading: false,
        isAuthed: true
      };
    case AUTH_FAILED:
      return {
        ...state,
        loading: false,
        isAuthed: false
      };
    case TOKEN_VERIFYING:
      return {
        ...state,
        isVerifying: true
      };
    case TOKEN_VERIFIED:
      return {
        ...state,
        isVerifying: false,
        isAuthed: action.payload.toLowerCase() === 'admin'
      };
    case TOKEN_VERIFIED_FAILED:
      return {
        ...state,
        isVerifying: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};
