import { POST_CREATING, POST_CREATED, POST_CREATE_FAILED } from './actions';

const initialState = {
  isCreating: false,
  isCreated: false,
  createFailed: false,
  stupid: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_CREATING:
      return {
        ...state,
        isCreating: true,
        isCreated: false
      };
    case POST_CREATED:
      return {
        ...state,
        isCreating: false,
        isCreated: true
      };
    case POST_CREATE_FAILED:
      return {
        ...state,
        isCreating: false,
        isCreated: false,
        createFailed: true
      };
    default:
      return state;
  }
};
