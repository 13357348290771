import React, { Component } from 'react';
import * as postActions from '../store/post/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';

const override = css`
  display: block;
  text-align: center;
  width: auto;
  margin-top: 250px;
`;

export class Post extends Component {
  componentDidMount () {
    this.props.dispatch(postActions.fetchPost(this.props.match.params.id));
  }
  render () {
    const { post, loading } = this.props;
    return (
      <React.Fragment>
        {!loading ? (
          <div>
            <h3>{post.title}</h3>
            <div>{post.content}</div>
            <div>
              <strong>Author:</strong> {post.author}
            </div>
            <div>
              <strong>Date Posted:</strong>
              {Moment(post.dateCreated).format('dddd, MMMM Do YYYY, h:mm a').toString()}
            </div>
          </div>
        ) : (
          <BeatLoader css={override} color={'#eee'} sizeUnit={'px'} size={30} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    post: state.post.post,
    loading: state.post.loading,
    error: state.post.error
  };
};

export default connect(mapStateToProps)(Post);
