export const POST_CREATING = 'POST_CREATING';
export const POST_CREATED = 'POST_CREATED';
export const POST_CREATE_FAILED = 'POST_CREATE_FAILED';

export const postCreatingBegin = () => ({
  type: POST_CREATING
});

export const postCreatedSuccess = () => ({
  type: POST_CREATED
});

export const postCreateFailed = () => ({
  type: POST_CREATE_FAILED
});

export const createPost = data => {
  return async dispatch => {
    dispatch(postCreatingBegin());
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/post/create`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      });
      const res = handleErrors(response);
      const json = await res.json();
      dispatch(postCreatedSuccess());
      return json;
    } catch (error) {
      dispatch(postCreateFailed());
      return console.error('Error:', error);
    }
  };
};

const handleErrors = response => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};
