import { POST_FETCHED, POST_FETCHING, POST_FETCHED_FAILED } from './actions';

const initialState = {
  post    : [],
  loading : false,
  error   : null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_FETCHING:
      return {
        ...state,
        loading : true,
        error   : null
      };
    case POST_FETCHED:
      return {
        ...state,
        loading : false,
        post    : action.payload.post
      };
    case POST_FETCHED_FAILED:
      return {
        ...state,
        loading : false,
        error   : action.payload.error,
        post    : []
      };
    default:
      return state;
  }
};
